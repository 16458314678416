import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'



function App() {
	
	const theme = createTheme({
		palette: {
			primary: { main: '#403501' },
			secondary: { main: '#485922' },
			light: { main: '#90A68D' },
			goldish: { main: '#73641A' },
			dark: { main: '#261D01' },
			backGray: { main: '#f8f8f8' },
		}
	})
	
	const categories = [ 'Clothes, Shoes & Bags', 'Sweets & Chocolates', 'Gifts for your Vegans', 'Costmetics & Beauty', 'Restaurants', 'Ready Meals' ]

  return (
	  <ThemeProvider theme={theme}>
	<>
	  <header>
	  <Box sx={{backgroundColor: 'dark.main', color: 'white', pl: {xs: 1, md: 8}, pt: 5, pb: 5}}>
	  <Stack direction='row'>
	<Typography variant='h4'>Take My Vegan Money</Typography>	
	</Stack>
	</Box>
	  </header>
	  <main><Box sx={{minHeight: '85vh', backgroundColor: 'backGray.main'}}>
	  <section>
	  <Box p={3} pb={9}>
	  <Typography variant='h2' mt={8} mb={1} align={'center'}>The Vegan Take Over</Typography>
	  <Typography variant='h4' mt={5} align={'center'}>Find vegan stuff</Typography>
	  </Box>
	  </section>

	  <section><Box p={5}>
	  <Grid container spacing={3} sx={{maxWidth: '1500px', margin: 'auto'}}>
		
	  {categories.map(c => 
	<Grid xs={12} md={4}>
	  <Card sx={{minHeight: '200px', backgroundColor: 'light.main', color: 'white'}}>
		<Typography variant='h4' align='center' sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>{c}</Typography>
	  </Card>
	  </Grid>
	  )}


	  </Grid>


	</Box></section>


	  </Box></main>
	  <footer>
	  <Box sx={{backgroundColor: 'primary.main', color: 'white', p: 5}}>
	  <p>Copyright 2022 Take My Vegan Money</p>
	  <p>Terms</p>
	  <p>Privacy</p>


	</Box>
	  </footer>





	  </>
	  </ThemeProvider>



  );
}

export default App;
